@import '~@angular/material/theming';

$length-4: 4px;
$length-8: 8px;
$length-12: 12px;
$length-16: 16px;
$length-24: 24px;
$length-32: 32px;
$length-48: 48px;
$length-64: 64px;
$length-72: 72px;
$length-96: 96px;
$length-128: 128px;
$length-192: 192px;
$length-256: 256px;
$length-384: 384px;
$length-512: 512px;
$length-640: 640px;
$length-768: 768px;
$length-960: 960px;

$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-30: 30px;
$font-size-36: 36px;
$font-size-48: 48px;
$font-size-60: 60px;
$font-size-72: 72px;

$primary: mat-palette($mat-indigo);
$accent: mat-palette($mat-deep-orange, A200, A100, A400);
$color-warn: #f44336;
$color-success: #039e12;

$theme: mat-light-theme($primary, $accent);
$foreground: map-get($theme, foreground);
$background: map-get($theme, background);

$dark-theme: mat-dark-theme($primary, $accent);
$dark-foreground: map-get($dark-theme, foreground);
$dark-background: map-get($dark-theme, background);

$sidebar-background-header-color: #272b35;
$sidebar-background-color: #2b303b;
$color-info: rgb(30, 136, 229);
