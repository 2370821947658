.default-box-shadow {
  box-shadow: 0 0 20px 0 rgba(76, 87, 125, 0.02);
}

.input-search {
  input.input-search {
    @apply font-medium text-gray-600;
  }

  label.mat-form-field-empty {
    span {
      @apply font-medium text-gray-500;
      opacity: 0.8;
    }
  }

  .mat-form-field-underline {
    display: none;
  }
}

.svg-fill-white {
  svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    @apply fill-white;
  }
}


.svg-fill-primary {
  svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    @apply fill-primary;
  }
}


.svg-fill-success {
  svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    @apply fill-success;
  }
}


.svg-fill-danger {
  svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    @apply fill-danger;
  }
}


.svg-35 {
  svg {
    width: 35px;
    height: 35px;
  }
}

.svg-40 {
  svg {
    width: 40px;
    height: 40px;
  }
}

.svg-45 {
  svg {
    width: 45px;
    height: 45px;
  }
}

.svg-50 {
  svg {
    width: 50px;
    height: 50px;
  }
}

.mat-step-header {
  .mat-step-icon-state-edit,
  .mat-step-icon-selected {
    @apply bg-primary-active;
  }

  .mat-step-icon-state-done {
    @apply bg-success-active;
  }

  .mat-step-icon-content {
    font-weight: 700;
  }

  .mat-step-text-label {
    font-weight: 600;
  }

}


body div a {
  @apply text-primary;
  &:hover {
    @apply text-primary-active;
  }
}
