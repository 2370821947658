@import "../../lib/src/theme";

body {
  $config: mat-typography-config();

  color: mat-color($foreground, text);
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: mat-color($background, background);

  font-family: mat-font-family($config);

  * {
    box-sizing: border-box;
  }

  .btn-rounded {
    min-width: 150px;
    border-radius: 20px !important;
    text-align: center;
    margin: 10px auto;
  }

  .btn-lg {
    height: 46px;
    border-radius: 30px !important;
  }

  .aligned-with-icon {
    position: absolute;
    margin-top: 5px;
    margin-left: 5px; /* optional */
  }

  .aligned-details-content {
    margin-top: 5px;
  }

  // Inner container in details tabs
  .inner-container {
    margin-top: $length-16;
    margin-left: $length-32;
    margin-right: $length-32;

    .mat-card-header-text {
      margin: 0 20px 0 10px;
    }

    .mat-card-avatar {
      width: inherit;
    }

    .mat-card-actions {
      padding: 0;
    }

    .edit-btn {
      cursor: pointer;
      margin-right: 10px;
    }

    .scroll {
      height: 300px
    }

    .details {
      @media screen and (max-width: 1020px) {
        display: block !important;
      }

      .column {
        margin: 10px 5px;

        .item {
          margin: 4px 0;
          padding: 15px 10px;
          font-size: 14px;
          border: 1px solid rgba(250, 250, 250, 0.8);
          background: rgba(250, 250, 250, 0.6);
          letter-spacing: 1px;

          .item-name {
            color: #000;
            font-weight: 500;
          }
        }

        .fixChipsRow {
          padding: 4px 10px;
        }

      }
    }

    .text-color {
      &.warn {
        color: $color-warn;
      }

      &.success {
        color: $color-success, 500;
      }
    }
  }
}
