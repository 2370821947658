@import "var";

body mat-card.mat-card {
  padding: $length-16;
  border-radius: 1px;

  &:not([class*='mat-elevation-z']) {
    @include mat-elevation(1);
  }

  &.margin {
    margin: $length-24;
  }

  .scroll {
    height: $length-384
  }

  mat-card-actions.mat-card-actions {
    margin: 0 0 0 auto;
    padding: 0;
  }
}
