@import 'var';
@import 'section';

@import 'ag-grid-community/src/styles/ag-grid.scss';
@import 'ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss';

.ag-theme-alpine {
  @include ag-theme-alpine(
      (
        odd-row-background-color: white,
        even-row-background-color: white,
      )
  );
}

// fontSizes
.fs12 {
  font-size: 12px;
}

.fs13 {
  font-size: 13px;
}

.fs14 {
  font-size: 14px;
}

.fs15 {
  font-size: 15px;
}

$spaceamounts: (
  0,
  5,
  10,
  12,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  75,
  100
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

.icon-align {
  display: flex;
  align-items: center;
}

.page {
  display: block;

  @media only screen and (min-width: 640px) {
    margin: $length-16 $length-24 $length-32 $length-24;
  }
}

.mat-tooltip {
  font-size: 14px !important;
  font-weight: 500;
  font-family: 'Poppins', sans-serif !important;
  background-color: #181c32 !important;
  color: white !important;
}

.ribbon-label {
  left: -5px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  background-color: #009ef7;
  box-shadow: 0 -1px 5px 0 rgb(0 0 0 / 10%);
  color: #fff;

  top: 8%;
  right: auto;
  transform: translateX(-5px) translateY(-50%);
  border-top-right-radius: 0.475rem;
  border-bottom-right-radius: 0.475rem;

  .ribbon-inner {
    border-top-right-radius: 0.475rem;
    border-bottom-right-radius: 0.475rem;
    z-index: -1;
    position: absolute;
    padding: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &::before {
      content: '';
      position: absolute;
      border-style: solid;
      border-color: transparent !important;
      bottom: -10px;
      border-width: 0 10px 10px 0;
      border-right-color: #181c32 !important;
      left: 0;
    }

    &::after {
      content: '';
      position: absolute;
      border-style: solid;
      border-color: transparent !important;
      bottom: -10px;
    }
  }
}

@keyframes down {
  0% {
    top: 55px;
  }
  100% {
    top: 71px;
  }
}

@keyframes down-horizontal {
  0% {
    top: 20px;
  }
  100% {
    top: 45px;
  }
}

.input-error {
  font-size: 11px;
  top: 71px;
  left: 1px;
  animation-duration: 0.3s;
  @apply font-semibold text-danger absolute;
}

.has-error .input-error {
  animation-name: down;
}

.horizontal {
  &.has-error .input-error {
    animation-name: down-horizontal;
  }

  .input-error {
    top: 45px;
  }
}

//Mat Dialog Modal
.mat-dialog-container {
  overflow: visible !important;

  &.ng-trigger-dialogContainer {
    box-shadow: unset !important;
    padding: 13px 24px;
    margin: 5px 0 10px;
  }
}

.cdk-overlay-container {
  overflow-y: visible !important;

  .cdk-global-overlay-wrapper {
    overflow-y: scroll !important;

    .cdk-overlay-pane {
      background-color: inherit;
      border-radius: 4px;
    }
  }
}

ng-select.my-selector {
  padding-bottom: 0;

  .ng-value-label {
    color: #5e6278;
    font-weight: 500;

    @apply fs14;
  }

  .ng-select-container {
    align-items: center;
    //background-color: rgba(255, 0, 0, 0.65);
    background-color: #f5f8fa;
    border-radius: 0.475rem !important;
    min-height: 42px;
    border-color: #f5f8fa;
    border-width: 1px;

    &:active,
    &:focus-within {
      background-color: #eef3f7;
      border-color: #eef3f7;
    }

    &:focus-visible {
      outline: unset;
    }

    &::after {
      display: none;
    }

    .ng-value-container {
      display: flex;
      align-items: center;
      padding: 0 0 0 1rem;
      border-top: 0;

      .ng-input {
        height: 26.5px;
        padding-left: 1rem;

        input {
          background-color: transparent;
          color: #5e6278;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.5;
          //padding-left: 1rem;
          //padding-right: 1rem;
          background-clip: padding-box;
          appearance: none;
          @apply fs14;
        }
      }

      .ng-value {
        width: 95%;
        z-index: 99;
      }

      .ng-placeholder {
        z-index: 99;
        color: rgba(94, 98, 120, 0.5);
        font-size: 14px;
        font-weight: 500;
        //padding-left: 1rem;
      }
    }

    .ng-clear-wrapper,
    .ng-arrow-wrapper {
      align-self: unset;
      bottom: unset;
    }

    .ng-arrow-wrapper {
      bottom: 4px;
    }
  }

  .ng-has-value .ng-placeholder {
    display: none;
  }

  &.country-selector {
    cursor: pointer !important;

    &.ng-untouched {
      &.ng-select-opened {
        .ng-value {
          padding-left: 1rem;
        }
      }

      img {
        margin-left: 0 !important;
      }
    }

    &.ng-touched {
      .ng-placeholder {
        margin-left: 1rem !important;
      }
    }

    .ng-select-container {
      &.ng-has-value {
        padding-top: 7px;
      }
    }

    div.ng-input > input[type='text'] {
      padding-left: 45px;
    }
  }

  // Dropdown selector
  ng-dropdown-panel.ng-dropdown-panel {
    @apply shadow-2xl rounded-b-md;

    &.ng-select-top {
      bottom: calc(100%);
    }

    &.ng-select-bottom {
      top: calc(100%);
      z-index: 99999999;
    }

    .ng-dropdown-panel-items {
      max-height: 320px;

      .ng-option {
        line-height: 2.5rem;
        min-height: 2.5rem;

        &.ng-option-marked {
          @apply bg-primary-light text-primary;
        }

        @apply font-medium text-gray-700  hover:bg-primary-light hover:text-primary;
      }
    }

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      @apply bg-white;
    }

    ::-webkit-scrollbar-thumb {
      @apply bg-secondary;
    }

    ::-webkit-scrollbar-thumb:hover {
      @apply bg-secondary;
    }
  }

  // Focused
  &.ng-select-focused {
    .ng-select-container .ng-value-container .ng-placeholder {
      display: none;
    }
  }

  // Disabled
  &.ng-select-disabled {
    cursor: not-allowed;

    .ng-select-container {
      // background-color: #e1e5e9;
      cursor: not-allowed;

      input {
        cursor: not-allowed;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  @apply bg-white;
}

::-webkit-scrollbar-thumb {
  @apply bg-secondary;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-secondary;
}

// new
div.toolbar-bellow {
  z-index: 99999;
}

formly-group.col-2 {
  formly-field {
    flex: 1;
  }

  &.left {
    formly-field {
      max-width: 70%;
    }
  }
}

.flex.left {
  .flex-half {
    width: 50%;

    .form-group {
      width: 70%;

      formly-wrapper-animation {
        width: 70%;
      }
    }
  }
}

.w70 {
  max-width: 71%;
}

@media only screen and (max-width: 768px) {
  form {
    formly-form {
      .flex.left {
        flex-direction: column;
        flex-wrap: unset;
        width: 100% !important;
        flex: 100% !important;
      }

      .flex-half {
        flex: 100% !important;
        width: 100% !important;

        .form-group {
          width: 100%;
        }
      }
    }

    .w70 {
      max-width: 100%;
    }
  }
}

formly-form {
  formly-field {
    formly-wrapper-animation {
      width: 100%;
    }
  }
}
