.section {
  word-wrap: break-word;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
  background-clip: border-box;
  width: 100%;

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    min-height: 70px;
    padding: 0 2.25rem;
    background-color: transparent;
    border-bottom: 1px solid #eff2f5;

    &:first-child {
      border-radius: calc(.475rem - 1px) calc(.475rem - 1px) 0 0;
    }

    .section-title {
      font-weight: 500;
      font-size: 1.275rem;
      color: #181c32;
      display: flex;
      align-items: center;

      a {
        color: #181c32;

        &:hover {
          @apply text-primary;
        }
      }

      .subtitle {
      }
    }
  }


}
