.display-flex {
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 780px) {
    display: block;
  }
}

.column {
  flex-direction: column;
}

.flex-half {
  flex: 50%;
}

.flex-1 {
  flex: 1 1 auto;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

