.my-btn {
  &.mat-stroked-button {
    border: unset !important;
    padding: 10px 20.5px !important;
    line-height: 1.5 !important;
    font-size: 0.875rem !important;
    border-radius: .475rem !important;

    svg {
      margin-left: 5px;
    }

    svg [fill]:not(.permanent):not(g) {
      transition: fill .3s ease;
      stroke: white !important;
    }

    mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
      stroke: white !important;
    }

    mat-spinner.mat-progress-spinner {
      overflow: visible !important;
    }
  }
}

.btn {
  line-height: 1.5;
  vertical-align: middle;
  user-select: none;
  padding: 10.750px 20.500px;
  font-size: 0.875rem;
  border-radius: .475rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  @apply outline-none cursor-pointer font-medium text-center border-0 shadow-none;

  .svg-icon {
    flex-shrink: 0;
    line-height: 0;
    margin-right: .5rem;

    svg {
      height: 22.75px;
      width: 22.75px;
    }

  }


}

.btn-small {
  padding: 8.150px 17.250px !important;
  font-size: 0.7rem !important;
}

.btn-icon {
  @apply flex items-center btn;

  .svg-icon {
    margin-right: 0;
  }

  svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
  }
}

.only-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: calc(1.5em + 1.5rem + 2px);
  width: calc(1.5em + 1.5rem + 2px);
}

// ************************************
// Base Buttons (1)
// ************************************

.btn-white {
  @apply btn bg-white hover:bg-gray-100;
  color: #7E8299
}

.btn-success {
  @apply btn bg-success text-white hover:bg-success-active;
}

.btn-primary {
  @apply btn bg-primary text-white hover:bg-primary-active my-btn;

  &.btn-icon {
    svg [fill]:not(.permanent):not(g) {
      @apply fill-white;
      opacity: 0.8;
    }
  }
}

.btn-light {
  @apply btn bg-light;
  color: #7e8299;

  &:hover {
    background-color: #e4e6ef;
  }
}

.btn-light-to-primary {
  @apply btn-light;

  &:hover {
    @apply bg-primary text-white;
  }
}

.btn-secondary {
  @apply btn bg-secondary;
  color: #3f4254;

  &:hover {
    background-color: #b5b5c3;
  }
}

.btn-info {
  @apply btn bg-info text-white hover:bg-info-active;
}

.btn-warning {
  @apply btn bg-warning text-white hover:bg-warning-active;
}

.btn-danger {
  @apply btn bg-danger text-white hover:bg-danger-active;
}

.btn-error {
  @apply btn bg-error text-white hover:bg-error-active;
}

.btn-dark {
  @apply btn bg-dark text-white;
}


// ************************************
// Light Buttons  (2)
// ************************************
.btn-light-primary-light {
  @apply btn bg-light text-gray-600  hover:text-primary;

  svg [fill]:not(.permanent):not(g) {
    @apply text-gray-600;
  }

  &:hover {
    svg [fill]:not(.permanent):not(g) {
      @apply fill-primary;
    }
  }

}


.btn-light-primary {
  @apply btn bg-primary-light text-primary hover:bg-primary hover:text-white;

  &.btn-icon {
    svg [fill]:not(.permanent):not(g) {
      @apply fill-primary;
    }
  }

  svg [fill]:not(.permanent):not(g) {
    @apply fill-primary;
  }

  &:hover {
    svg [fill]:not(.permanent):not(g) {
      @apply fill-white;
    }
  }

}

.btn-light-success {
  @apply btn bg-success-light text-success hover:bg-success hover:text-white;
}

.btn-light-info {
  @apply btn bg-info-light text-info hover:bg-info hover:text-white;
}

.btn-light-warning {
  @apply btn bg-warning-light text-warning hover:bg-warning hover:text-white;
}

.btn-light-danger {
  @apply btn bg-danger-light text-danger hover:bg-danger hover:text-white;
}

.btn-light-error {
  @apply btn bg-error-light text-error hover:bg-error hover:text-white;
  svg [fill]:not(.permanent):not(g) {
    @apply fill-error;
  }

  &:hover {
    svg [fill]:not(.permanent):not(g) {
      @apply fill-white;
    }
  }
}

.btn-light-dark {
  @apply btn bg-dark-light text-dark hover:bg-dark hover:text-white;
}


// ************************************
// ICON Buttons  (3)
// ************************************
.btn-icon-primary {
  @apply btn-icon text-primary bg-light;
  svg [fill]:not(.permanent):not(g) {
    @apply fill-primary;
  }
}

.btn-icon-success {
  @apply btn-icon text-success bg-light;
  svg [fill]:not(.permanent):not(g) {
    @apply fill-success;
  }
}

.btn-icon-warning {
  @apply btn-icon text-warning bg-light;
  svg [fill]:not(.permanent):not(g) {
    @apply fill-warning;
  }
}

.btn-icon-info {
  @apply btn-icon text-info bg-light;
  svg [fill]:not(.permanent):not(g) {
    @apply fill-info;
  }
}

.btn-icon-danger {
  @apply btn-icon text-danger bg-light;
  svg [fill]:not(.permanent):not(g) {
    @apply fill-danger;
  }
}

.btn-icon-error {
  @apply btn-icon text-error bg-light;
  svg [fill]:not(.permanent):not(g) {
    @apply fill-error;
  }
}

.btn-icon-dark {
  @apply btn-icon text-dark bg-light;
  svg [fill]:not(.permanent):not(g) {
    @apply fill-dark;
  }
}

.btn-icon-gray-500 {
  @apply btn-icon text-gray-500 bg-light;
  svg [fill]:not(.permanent):not(g) {
    @apply fill-gray-500;
  }
}

.btn-icon-gray-600 {
  @apply btn-icon text-gray-600 bg-light;
  svg [fill]:not(.permanent):not(g) {
    @apply fill-gray-600;
  }
}

.btn-icon-gray-700 {
  @apply btn-icon text-gray-700 bg-light;
  svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    @apply fill-gray-700;
  }
}

.btn-icon-gray-800 {
  @apply btn-icon text-gray-800 bg-light;
  svg [fill]:not(.permanent):not(g) {
    @apply fill-gray-800;
  }
}


// ************************************
// Active Buttons  (4)
// ************************************
.btn-active-white {
  @apply btn hover:bg-white hover:text-gray-600;
}

.btn-active-primary {
  @apply btn hover:bg-primary hover:text-white;
}

.btn-active-light {
  @apply btn hover:bg-light hover:text-gray-600;
}

.btn-active-secondary {
  @apply btn hover:bg-secondary hover:text-gray-800;
}

.btn-active-success {
  @apply btn hover:bg-success hover:text-white;
}

.btn-active-info {
  @apply btn hover:bg-info hover:text-white;
}

.btn-active-warning {
  @apply btn hover:bg-warning hover:text-white;
}

.btn-active-danger {
  @apply btn hover:bg-danger hover:text-white;
}

.btn-active-error {
  @apply btn hover:bg-error hover:text-white;
}

.btn-active-dark {
  @apply btn hover:bg-dark hover:text-white;
}


// ************************************
// Active-Light Buttons  (5)
// ************************************
.btn-active-light-primary {
  @apply btn hover:bg-primary-light hover:text-primary;
}

.btn-active-light-success {
  @apply btn hover:bg-success-light hover:text-success;
}

.btn-active-light-info {
  @apply btn hover:bg-info-light hover:text-info;
}

.btn-active-light-warning {
  @apply btn hover:bg-warning-light hover:text-warning;
}

.btn-active-light-danger {
  @apply btn hover:bg-danger-light hover:text-danger;
}

.btn-active-light-error {
  @apply btn hover:bg-error-light hover:text-error;
}

.btn-active-light-dark {
  @apply btn hover:bg-dark-light hover:text-dark;
}


// ************************************
// Active-Color Buttons  (6)
// ************************************

.btn-active-color-primary {
  @apply btn bg-light hover:text-primary;
}

.btn-active-color-success {
  @apply btn bg-light  hover:text-success;
}

.btn-active-color-info {
  @apply btn bg-light hover:text-info;
}

.btn-active-color-warning {
  @apply btn bg-light hover:text-warning;
}

.btn-active-color-danger {
  @apply btn bg-light hover:text-danger;
}

.btn-active-color-error {
  @apply btn bg-light hover:text-error;
}

.btn-active-color-dark {
  @apply btn bg-light hover:text-dark;
}


// ************************************
// Icon Hover  (7)
// ************************************
.btn-icon-primary-hover {
  @apply btn-icon btn-active-color-primary;
  &:hover {
    svg [fill]:not(.permanent):not(g) {
      @apply fill-primary;
    }
  }
}

.btn-icon-success-hover {
  @apply btn-icon btn-active-color-success;
  &:hover {
    svg [fill]:not(.permanent):not(g) {
      @apply fill-success;
    }
  }
}

.btn-icon-warning-hover {
  @apply btn-icon btn-active-color-warning;
  &:hover {
    svg [fill]:not(.permanent):not(g) {
      @apply fill-warning;
    }
  }
}

.btn-icon-info-hover {
  @apply btn-icon btn-active-color-info;
  &:hover {
    svg [fill]:not(.permanent):not(g) {
      @apply fill-info;
    }
  }
}

.btn-icon-danger-hover {
  @apply btn-icon btn-active-color-danger;
  &:hover {
    svg [fill]:not(.permanent):not(g) {
      @apply fill-danger;
    }
  }
}

.btn-icon-error-hover {
  @apply btn-icon btn-active-color-error;
  &:hover {
    svg [fill]:not(.permanent):not(g) {
      @apply fill-error;
    }
  }
}


.small-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  line-height: 1.5;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  margin-left: 1rem;
  cursor: pointer;


  &.ml-0 {
    margin-left: 0;
  }

  &.ml-2,
  &.half-ml {
    margin-left: 0.5rem;
  }

  svg {
    height: 1.35rem;
    width: 1.35rem;

    [fill]:not(.permanent):not(g) {
      transition: fill .3s ease;
      fill: #a1a5b7;
    }
  }
}


.small-btn-primary {
  @apply small-btn;
  &:hover {
    @apply bg-primary-light;

    svg {
      [fill]:not(.permanent):not(g) {
        @apply fill-primary;
      }
    }
  }
}

.small-btn-success {
  @apply small-btn;
  &:hover {
    @apply bg-success-light;

    svg {
      [fill]:not(.permanent):not(g) {
        @apply fill-success;
      }
    }
  }
}


.small-btn-error {
  @apply small-btn;
  &:hover {
    @apply bg-error-light;

    svg {
      [fill]:not(.permanent):not(g) {
        @apply fill-error;
      }
    }
  }
}

//New
.small-btn-success-active {
  @apply small-btn bg-success-light;
  svg {
    [fill]:not(.permanent):not(g) {
      @apply fill-success;
    }
  }

  &:hover {
    @apply bg-light;

    //svg {
    //  [fill]:not(.permanent):not(g) {
    //    @apply fill-success;
    //  }
    //}
  }
}
