.text-icon {
  @apply flex items-center text-gray-400 text-sm;

  .svg-icon {
    line-height: 0;
    margin-right: .25rem;

    svg {
      height: 16.250px;
      width: 16.250px;

      [fill]:not(.permanent):not(g) {
        transition: fill .3s ease;
        fill: #a1a5b7;
        @apply fill-gray-500;
      }
    }


  }
}
