@import '~@angular/material/theming';
@import '~@coachcare/datepicker/theming';
@import '~flag-icon-css/css/flag-icon.css';
@import '~@ng-select/ng-select/themes/material.theme.css';
@import '~material-design-icons/iconfont/material-icons.css';
@import 'styles/var';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

// material theme
@include mat-core();
@include mat-datepicker-theme($theme);
@include angular-material-theme($theme);

// tailwind
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'styles/tailwind/buttons';
@import 'styles/tailwind/textIcons';
@import 'styles/tailwind/helper';

.dark {
  @include mat-datepicker-theme($dark-theme);
  @include angular-material-theme($dark-theme);
}

// helpers
@import 'styles/helpers';

// components
@import 'styles/cards';
@import 'styles/ng-select';
@import 'styles/flex-columns';

// date time picker
@import '~ng-pick-datetime/assets/style/picker.min.css';

body {
  * {
    font-family: 'Poppins', sans-serif;
  }

  mat-list.mat-list {
    mat-list-item.mat-list-item {
      &.small {
        height: $length-48;
        font-size: $font-size-14;
        letter-spacing: 2px;
      }
    }
  }

  mat-chip-list.mat-chip-list {
    .mat-chip-list-wrapper {
      margin: 0;

      mat-chip.mat-chip {
        white-space: nowrap;
      }

      mat-chip.mat-standard-chip {
        min-height: 25px;
        margin: 0;
      }
    }
  }

  nav.nav {
    box-shadow: none !important;

    .mat-tab-link {
      &.mat-tab-label-active {
        opacity: 1;
        color: mat-color($foreground, text);
      }
    }
  }

  a {
    color: mat-color($primary);
    text-decoration: none;

    &:hover {
      @apply text-primary;
    }
  }
}

.main-container {
  @apply px-20;
}

.card-shadow {
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 10%);
  &.shadow-none {
    box-shadow: unset;
  }
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  @apply bg-success;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #a9dbc1;
}

.separator {
  display: block;
  height: 0;
  border-bottom: 1px solid #eff2f5;
}

.not_set {
  font-weight: 400;
}
